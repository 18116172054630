import React, { useEffect, useState } from "react";
import { navigate, useIntl, FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

import api from "../../service/api";
import { sanitizeError, errorKeyNavigateSystemErrorPage } from "../../app.utils";
import * as constants from "../../app.constant";
import alertStore from "../../store/alertStore";
import errorTranslationKeyStore from "../../store/errorTranslationKeyStore";
import usePrevious from "../../hooks/use-previous";
import AppLayout from "../../components/shared/AppLayout";
import DateInputField from "../../components/form/date-field";
import SelectRadioField from "../../components/form/select-radio-field";
import InputField from "../../components/form/input-field";
import disclaimerImg from "../../assets/images/page-customer-collection/disclaimer.png";

const PageCustomerCollection = () => {
    const today = new Date();
    const intl = useIntl();
    const { messages, locale } = intl;
    const localeTranslation = locale + "_TW";
    const [ deviceApiRes, setDeviceApiRes ] = useState([]);
    const deviceList = deviceApiRes?.map((data) => { return data.deviceName });
    const deviceNameList = [...new Set(deviceList)].map(value => Object.assign({value: value, label: value}));
    const [ deviceStorageList, setDeviceStorageList ] = useState([]);
    const [ deviceColorList, setDeviceColorList ] = useState([]);

    const purchaseMethodOptions = [
        {
            label: messages["customerCollection.form.purchaseMethod.options.0.label"],
            value: "燦坤TK3C實體門市",
        },
        {
            label: messages["customerCollection.form.purchaseMethod.options.1.label"],
            value: "燦坤線上購物",
        },
    ];

    const genderOptions = [
        {
            label: messages["customerCollection.form.gender.options.0.label"],
            value: "male",
        },
        {
            label: messages["customerCollection.form.gender.options.1.label"],
            value: "female",
        },
    ];

    const userAcknowledgementOptions = [
        {
            label: messages["customerCollection.form.userAcknowledgement.options.0.label"],
            value: "Yes",
        },
        {
            label: messages["customerCollection.form.userAcknowledgement.options.1.label"],
            value: "No",
        },
    ];

    const errors = {
        purchaseMethod: messages["customerCollection.form.errorMsgDate"],
        purchaseDate: messages["customerCollection.form.errorMsgDate"],
        invoiceNumber: messages["customerCollection.form.errorMsgReq"],
        fullName: messages["customerCollection.form.errorMsgReq"],
        nationalId: messages["customerCollection.form.errorMsgReq"],
        gender: messages["customerCollection.form.errorMsgRadio"],
        birthDate: messages["customerCollection.form.errorMsgDate"],
        postalCode: messages["customerCollection.form.errorMsgReq"],
        mailAddress: messages["customerCollection.form.errorMsgReq"],
        contactNumber: messages["customerCollection.form.errorMsgReq"],
        email: messages["customerCollection.form.errorMsgReq"],
        deviceName: messages["customerCollection.form.errorMsgRadio"],
        deviceStorage: messages["customerCollection.form.errorMsgRadio"],
        deviceColor: messages["customerCollection.form.errorMsgRadio"],
        serialNumber: messages["customerCollection.form.errorMsgReq"],
        userAcknowledgement: messages["customerCollection.form.userAcknowledgement.requiredAgree"],
    };

    const formik = useFormik({
        initialValues: {
            purchaseMethod: "",
            purchaseDate: "",
            invoiceNumber: "",
            nationalId: "",
            fullName: "",
            gender: "",
            birthDate: "",
            postalCode: "",
            mailAddress: "",
            contactNumber: "",
            email: "",
            deviceName: "",
            deviceStorage: "",
            deviceColor: "",
            serialNumber: "",
            userAcknowledgement: "",
        },
        validationSchema: Yup.object({
            purchaseMethod: Yup.string()
                .required(errors.purchaseMethod),
            purchaseDate: Yup.string()
                .required(errors.purchaseDate),
            invoiceNumber: Yup.string()
                .required(errors.invoiceNumber),
            nationalId: Yup.string()
                .required(errors.nationalId),
            fullName: Yup.string()
                .required(errors.fullName),
            gender: Yup.string()
                .required(errors.gender),
            birthDate: Yup.string()
                .required(errors.birthDate),
            postalCode: Yup.string()
                .required(errors.postalCode),
            mailAddress: Yup.string()
                .required(errors.mailAddress),
            contactNumber: Yup.string()
                .required(errors.contactNumber),
            email: Yup.string()
                .required(errors.email),
            deviceName: Yup.string()
                .required(errors.deviceName),
            deviceStorage: Yup.string()
                .required(errors.deviceStorage),
            deviceColor: Yup.string()
                .required(errors.deviceColor),
            serialNumber: Yup.string()
                .required(errors.serialNumber),
            userAcknowledgement: Yup.string()
                .required(errors.userAcknowledgement)
                .matches(userAcknowledgementOptions[0].value, errors.userAcknowledgement),
        }),
        onSubmit: (values, action) => {
            action.setSubmitting(true);
            onHandleSubmit();
        },
    });

    const previousFormikValues = usePrevious(formik.values);

    const goToSuccessPage = () => {
        navigate(constants.ROUTES.CUSTOMER_COLLECTION_SUCCESS);
    };

    const getFirstName = (value) => {
        if (value === "male") {
            return "Mr.";
        } else if (value === "female") {
            return "Mrs.";
        }
    };

    const onHandleSubmit = async () => {
        const dateFormat = "YYYY-MM-DD";
        const timeFormat = "T00:00:00.000+0000";
        const deviceModelFromDeviceColorSelection = formik.values.deviceColor;

        try {
            const payload = {
                purchaseMethod: formik.values.purchaseMethod,
                purchaseDate: moment(formik.values.purchaseDate, constants.DATE_FORMAT).format(dateFormat) + timeFormat,
                invoiceNumber: formik.values.invoiceNumber,
                nationalId: formik.values.nationalId,
                fullName: formik.values.fullName,
                gender: formik.values.gender,
                firstName: getFirstName(formik.values.gender),
                birthDate: moment(formik.values.birthDate, constants.DATE_FORMAT).format(dateFormat) + timeFormat,
                postalCode: formik.values.postalCode,
                mailAddress: formik.values.mailAddress,
                contactNumber: formik.values.contactNumber,
                email: formik.values.email,
                deviceModel: deviceModelFromDeviceColorSelection,
                serialNumber: formik.values.serialNumber,
                nationality: "taiwanese",
                partnerCode: "hotai",
                countryCode: "TW",
                serialNumberType: "serialNumber",
            };
            await api.post.customerCollection(payload);
            goToSuccessPage();
        } catch (error) {
            formik.setSubmitting(false);
            const errorTranslationKey = error?.response?.data?.translationKey;

            alertStore.updateAlertMessage(sanitizeError(error));
            if (errorTranslationKey) {
                errorTranslationKeyStore.updateTranslationKey(errorTranslationKey);
                errorKeyNavigateSystemErrorPage(errorTranslationKey);
            }
        }
    };

    const onHandleGetDeviceList = async () => {
        const payload = {
            countryCode: "TW",
            partnerCode: "hotai",
        };
        try {
            const response = await api.get.getDeviceListDetails(payload);
            setDeviceApiRes(response?.data);
        } catch (error) {
            const errorTranslationKey = error?.response?.data?.translationKey;

            alertStore.updateAlertMessage(sanitizeError(error));
            if (errorTranslationKey) {
                errorTranslationKeyStore.updateTranslationKey(errorTranslationKey);
                errorKeyNavigateSystemErrorPage(errorTranslationKey);
            }
        }
    }

    useEffect(() => {
        onHandleGetDeviceList();
    }, []);

    useEffect(() => {
        if (!previousFormikValues) return;

        const deviceNameChanged = previousFormikValues.deviceName !== formik.values.deviceName;

        if (deviceNameChanged) {
            const filteredStorageList = deviceApiRes.filter((value) => value.deviceName === formik.values.deviceName);
            const processedStorageList = filteredStorageList.map((value) => {
                return {
                    id: value.id,
                    label: value.deviceStorage,
                    value: value.deviceStorage,
                };
            });
            const uniqStorageList = Object.values(
                processedStorageList.reduce((acc, obj) => ({ ...acc, [obj.label]: obj }), {})
            );
            setDeviceStorageList(uniqStorageList);
            formik.setFieldValue("deviceStorage", "");
            formik.setFieldValue("deviceColor", "");
        }

        const deviceStorageChanged = previousFormikValues.deviceStorage !== formik.values.deviceStorage;

        if (deviceStorageChanged) {
            const filteredColorList = deviceApiRes.filter((value) => {
                return (
                    value.deviceStorage === formik.values.deviceStorage && value.deviceName === formik.values.deviceName
                );
            });
            const uniqColorList = filteredColorList.map((value) => {
                return {
                    id: value.id,
                    label: value.deviceColor[localeTranslation],
                    value: value.deviceModel,
                };
            });
            setDeviceColorList(uniqColorList);
            formik.setFieldValue("deviceColor", "");
        }
    }, [formik, previousFormikValues, deviceApiRes, localeTranslation]);

    return (
        <AppLayout hasHeader={true} hasHeaderLogo={true} isBackBtn={true} hasFooter={true} prevUrl={constants.ROUTES.PLANS} isLoading={formik.isSubmitting}>

            <div className="app-page page-customer-collection">

                <div className="customer-collection">

                    <div className="customer-collection__container container">

                        <form className="customer-collection__form" noValidate autoComplete="off" onSubmit={formik.handleSubmit}>

                            <h2 className="customer-collection__title text-bold">
                                <FormattedMessage id="customerCollection.title" />
                            </h2>

                            <div className="customer-collection__disclaimer">
                                <FormattedHTMLMessage id="customerCollection.disclaimer" values={{url: "https://www.samsung.com/tw/support/"}} />
                                <img className="customer-collection__disclaimer-img img-fluid" src={disclaimerImg} alt="" />
                            </div>

                            <SelectRadioField
                                labelHtml={<FormattedHTMLMessage id="customerCollection.form.userAcknowledgement.label" />}
                                name="userAcknowledgement"
                                options={userAcknowledgementOptions}
                                formik={formik}
                                flexDirection="column"
                            />

                            <SelectRadioField
                                labelHtml={<FormattedHTMLMessage id="customerCollection.form.purchaseMethod.label" />}
                                name="purchaseMethod"
                                options={purchaseMethodOptions}
                                formik={formik}
                                flexDirection="column"
                            />

                            <DateInputField
                                label={messages["customerCollection.form.purchaseDate"]}
                                name="purchaseDate"
                                formik={formik}
                                maxDate={today}
                            />

                            <InputField
                                label={messages["customerCollection.form.invoiceNumber"]}
                                placeholder={messages["customerCollection.form.placeholder"]}
                                name="invoiceNumber"
                                formik={formik}
                            />

                            <InputField
                                label={messages["customerCollection.form.fullName"]}
                                placeholder={messages["customerCollection.form.placeholder"]}
                                name="fullName"
                                formik={formik}
                            />

                            <InputField
                                label={messages["customerCollection.form.nationalId"]}
                                placeholder={messages["customerCollection.form.placeholder"]}
                                name="nationalId"
                                formik={formik}
                            />

                            <SelectRadioField
                                labelHtml={<FormattedHTMLMessage id="customerCollection.form.gender.label" />}
                                name="gender"
                                options={genderOptions}
                                formik={formik}
                                flexDirection="column"
                            />

                            <DateInputField
                                label={messages["customerCollection.form.birthDate"]}
                                name="birthDate"
                                formik={formik}
                                maxDate={today}
                            />

                            <InputField
                                label={messages["customerCollection.form.postalCode"]}
                                placeholder={messages["customerCollection.form.placeholder"]}
                                name="postalCode"
                                formik={formik}
                            />

                            <InputField
                                label={messages["customerCollection.form.mailAddress"]}
                                placeholder={messages["customerCollection.form.placeholder"]}
                                name="mailAddress"
                                formik={formik}
                            />

                            <InputField
                                label={messages["customerCollection.form.contactNumber"]}
                                placeholder={messages["customerCollection.form.placeholder"]}
                                name="contactNumber"
                                formik={formik}
                            />

                            <InputField
                                label={messages["customerCollection.form.email"]}
                                placeholder={messages["customerCollection.form.placeholder"]}
                                name="email"
                                formik={formik}
                            />

                            {deviceNameList?.length > 0 && (
                                <SelectRadioField
                                    labelHtml={<FormattedHTMLMessage id="customerCollection.form.deviceName" />}
                                    name="deviceName"
                                    options={deviceNameList}
                                    formik={formik}
                                    flexDirection="column"
                                />
                            )}

                            {formik.values.deviceName && (
                                <SelectRadioField
                                    labelHtml={<FormattedHTMLMessage id="customerCollection.form.deviceStorage" />}
                                    name="deviceStorage"
                                    options={deviceStorageList}
                                    formik={formik}
                                    flexDirection="column"
                                />
                            )}

                            {formik.values.deviceStorage && (
                                <SelectRadioField
                                    labelHtml={<FormattedHTMLMessage id="customerCollection.form.deviceColor" />}
                                    name="deviceColor"
                                    options={deviceColorList}
                                    formik={formik}
                                    flexDirection="column"
                                />
                            )}

                            <InputField
                                label={messages["customerCollection.form.serialNumber"]}
                                placeholder={messages["customerCollection.form.placeholder"]}
                                name="serialNumber"
                                formik={formik}
                            />

                            <p className="btn-container btn-container--center">
                                <button className="btn btn-lg btn-primary" type="submit" disabled={formik.isSubmitting || !formik.isValid}>
                                    <FormattedMessage id="customerCollection.form.submitCta" />
                                </button>
                            </p>

                        </form>

                    </div>

                </div>

            </div>

        </AppLayout>
    );
};

export default PageCustomerCollection;
